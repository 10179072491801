.header__container {
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
}

.header__container .left__container img {
  cursor: pointer;
}

.header__container .middle__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.header__container .middle__container a {
  font-family: "GilroyLight";
  color: #000;
  text-decoration: none;
  font-size: 16px;
}

.header__container .right__container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
}

.header__container .right__container img {
  cursor: pointer;
}
