.chats-btn {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid black;
  font-family: "GilroyBold";
  background-color: transparent;
}

.com-btn {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #dfdfdf;
  font-family: "GilroyBold";
  background-color: transparent;
  color: gray;
}

.chat-list {
}
