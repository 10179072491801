/* FourSidedCircularImage.css */
.display {
  padding-left: 110px;
}

.circular-image1 {
  width: 200px;
  height: 200px;
  background-color: #ffe500;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  margin: 0 auto;
  /* margin-left: 50px; */
}

.circular-image2 {
  width: 200px;
  height: 200px;
  background-color: #ffe500;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  margin: 0 auto;
  /* margin-right: -15px; */
  /* margin-left: -10px; */
}

.circular-image img {
  max-width: 100%;
  max-height: 100%;
}

.right-radius-box {
  border-radius: 0% 47% 47% 0; /* Set left side radius to 50% */
  width: 50%; /* Adjust the width as needed */
  border: 3px solid #0000;
  background-image: linear-gradient(white, white),
    linear-gradient(to left, blue, yellow);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-left: 50px;
}

.left-radius-box {
  /* position: relative; */
  width: 50%;

  border: 3px solid #0000;
  border-radius: 47% 0 0 47%;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, blue, yellow);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.right-box {
  /* margin-left: 50px; */
}

.top-block {
  padding-inline: 0;
}
