.connect-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  /* margin-top: 2px; */
  /* margin-left: 140px; */
}

.connect-title {
  font-family: "GilroyLight";
  /* margin-right: 20px; */
}

.connect-btn {
  border-radius: 4px;
  border: transparent;
  background-color: #fee500;
  height: 30px;
  /* width: 100px; */
  font-family: "GilroyLight";
  font-size: 13px;
}

.disconnect {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  height: 30px;
  /* width: 100px; */
  font-family: "GilroyLight";
  font-size: 13px;
}
