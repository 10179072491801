.about-container {
  display: flex;
  margin-left: 100px;
  margin-top: 50px;
  margin-right: 100px;
}

.left-container {
  display: grid;
  text-align: right;
  margin-right: 120px;
}

.read-more-btn {
  width: 125px;
  height: 40px;
  background-color: yellow;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.right-container {
  margin-left: 120px;
  /* margin-right: 100px; */
}

.video-container {
  width: 120%;
  height: 440px;
  /* filter: blur(5px); */
  /* justify-items: center;
    justify-content: center;
    align-items: center; */
  /* padding-left: 15%; */
  /* padding-top: 5%; */
  margin-right: 50%;
  margin-top: -20px;
  /* margin-top: 30px; */
}

.artist-container {
  margin-left: 100px;
  display: flex;
}

.child-container {
  width: 120%;
  height: 420px;
  /* height: 350px;
    /* margin-top: 150px; */
  margin-top: 2%;
}

.artist-right-container {
  margin-left: 100px;
  margin-top: 30px;
  margin-right: 100px;
}

.point {
  font-family: "GilroyLight";
  margin-top: 0;
  margin-left: -3%;
}

.point-icon {
  width: 20px;
  height: 20px;
  /* margin-top: 13px; */
  margin-right: 10px;
  margin-left: -3%;
}

.artist {
  margin-top: 20px;
}
