.track__container {
  font-family: var(--font-gilroy-extrabold);
  background-color: #fff;
  margin: 20px 0;
  padding: 24px;
  border-radius: 6px;
}

.track-section {
  display: flex;
  flex-direction: column;
}

.track__heading {
  font-weight: 500;
  font-family: "GilroyBold";
  font-size: 20px;
  font-style: normal;
  margin-bottom: 18px;
}

.track__option {
  font-weight: 400;
  font-family: "GilroyLight";
  font-size: 14px;
  margin-left: 8px;
}

.track-option-container {
  display: flex;
  flex-direction: column;
}

.track-option {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.track-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  font-size: 32px;
  border-radius: 50%;
}

.active {
  background-color: #ffe500;
}

.inactive {
  background-color: #efeff0;
}

.line {
  background-color: #ffe500;
  width: 1px;
  height: 16px;
  margin-left: 12px;
}
/* 
.track-status svg{

} */
