.activity__status__container {
  width: fit-content;
  margin: 2rem auto 1rem auto;
  text-align: center;
}

.activity__status__container .title {
  background: #ffe445;
  /* border: 2px solid #d1be15; */
  border-radius: 3px;
  margin-bottom: 3px;
  padding: 5px 10px;
}

.activity__status__container .title p {
  font-family: "GilroyLight";
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.activity__status__container .date {
  font-family: "GilroyLight";
  color: #717171;
  font-size: 12px;
  font-weight: 400;
}
