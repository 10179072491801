.support__container {
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  font-family: var(--font-gilroy-extrabold);
}

.support__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.support__heading {
  font-size: 20px;
  font-weight: 500;
  font-family: "GilroyBold";
}

.support__label {
  font-size: 16px;
  font-weight: 500;
  font-family: "GilroyBold";
  margin-bottom: 5px;
}

.support__value {
  font-size: 14px;
  font-weight: 400;
  font-family: "GilroyLight";
  color: #717171;
}
