.reply_name {
  font-family: "GilroyBold";
  font-size: 16px;
  color: black;
}

.reply {
  color: #717171;
  font-size: 16px;
  font-family: "GilroyLight";
}

.reply_time {
  color: #889696;
  font-size: 12px;
  font-family: "GilroyLight";
}

.reply_btn {
  color: #889696;
  font-size: 14px;
  font-family: "GilroyLight";
}
