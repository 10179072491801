.info-cont {
  background-color: white;
  padding: 10px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 120%;
}

.bi-title {
  font-size: 16px;
  font-family: "GilroyBold";
  margin-bottom: 0;
}

.bi-desp {
  font-size: 12px;
  color: #717171;
  font-family: "GilroyLight";
}

.bi-label {
  color: #717171;
  font-size: 14px;
  font-family: "GilroyBold";
  margin-top: 15px;
}

.set-hg {
  border: 1px solid #ccc;
  height: 36px;
}

.set-hg:focus {
  border-color: #fee500;
}

.error-border {
  border: 1px solid red;
  height: 36px;
}
