.msg-name {
  font-size: 16px;
  color: black;
  font-family: "GilroyBold";
  margin-bottom: 0;
}

.msg-desp {
  font-size: 14px;
  color: black;
  font-family: "GilroyLight";
  margin-top: 2px;
}

.msg-time {
  font-size: 12px;
  color: #889696;
  font-family: "GilroyLight";
}

.msg-count {
  font-size: 12px;
  color: black;
  font-family: "GilroyLight";
  border-radius: 50%;
  background-color: #fee500;
  height: 20px;
  width: 20px;
  text-align: center;
  /* margin-left: 20px; */
  /* margin-top: -8px; */
}
