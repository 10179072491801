.regular {
  font-size: 12px;
  font-family: "GilroyBold";
  background-color: #fee500;
  border-color: #fee500;
  width: 150px;
}

.verified {
  font-size: 12px;
  font-family: "GilroyBold";
  border-radius: 4px;
  border-color: #00ba88;
  color: #00ba88;
  background-color: transparent;
  width: 150px;
}

.submitted {
  font-size: 12px;
  font-family: "GilroyBold";
  border-radius: 4px;
  border-color: #5f2eea;
  color: #5f2eea;
  background-color: transparent;
  width: 150px;
}

.failed {
  font-size: 12px;
  font-family: "GilroyBold";
  border-radius: 4px;
  border-color: #d90429;
  color: #d90429;
  background-color: transparent;
  width: 150px;
}
