.profile__skills__container {
  margin: 20px 0;
}

.profile__skills__container h2 {
  text-align: left;
  color: var(--Text-Color---1, #717171);
  font-family: "GilroyLight";
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
}

.skill__item {
  display: inline-block;
  font-family: "GilroyLight";
  font-size: 12px;
  font-weight: 400;
  margin: 5px 0;
  padding: 6px 7px;
  background: #efeff0;
  margin-right: 5px;
}

.skills__buttons__container {
  display: flex;
  gap: 6px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.skills__buttons__container .skills__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  font-family: "GilroyBold";
}
