.post-collab-header {
  width: 100%;
  background-color: white;
  display: flex;
  margin-top: -30px;
}

.post-collab-title {
  font-size: 16px;
  font-family: "GilroyBold";
  margin-left: 50px;
}

.right-buttons {
  width: 100px;
  height: 44px;
  font-size: 16px;
  font-family: "GilroyLight";
  background-color: white;
}

.cancel-button {
  margin-right: 10px;
  margin-left: 60px;
}

.post-button {
  background-color: #fee500;
  border: 0;
}

.right-buttons-container {
  /* margin-right: -40px; */
}
