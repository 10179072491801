.container1 {
  display: grid;
  margin-left: 100px;
  margin-top: 50px;
  margin-right: 100px;
  /* align-items: center; */
  /* background-color: #f0f0f0; */
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  /* padding: 8px;
    width: 300px; */
}

.p1 {
  color: yellow;
  /* font-weight: 600; */
  /* font-size: 24px; */
}

.search-bar {
  /* display: flex; */
  align-items: center;
  /* background-color: #f0f0f0; */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 500px;
}

.search-icon {
  color: #555;
  margin-right: 8px;
}

input {
  border: none;
  outline: none;
  width: 100%;
}

.button-Container {
  display: flex;
  margin-top: 10px;
  /* justify-content: center; Center the buttons horizontally */
}

.button-style {
  margin-right: 10px; /* Add some spacing between buttons */
  /* padding: 10px 20px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  /* padding-inline: 8px; */
  /* padding: 10px; */
  padding: 8px;
  cursor: pointer;
}

.p2 {
  font-weight: 600;
  font-size: 18px;
  margin-right: 10px;
}

.popular {
  margin-right: 20px;
  font-family: "GilroyBold";
}

.search-container {
  /* padding-left: 100px; */
  /* padding-right: 50px; */
}
