body {
  overflow-x: hidden;
}
.container-fluid {
  /* padding-top: 7%; */
}
.form-group {
  margin-top: 0.5rem;
  font-family: "GilroyLight";
}
.form-group label {
  font-family: "GilroyBold";
  margin-bottom: 0rem;
  font-size: 15px;
}

.form-group input {
  font-family: "GilroyLight";
}

.form-group select {
  font-family: "GilroyLight";
}

.form-group option {
  font-family: "GilroyLight";
}

.btn {
}
.button-margin {
  margin-top: -20px;
}

.back {
  /* margin-left: 250px; */
  /* width: 300px; */
  padding: 10px 10px;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 10%;
  margin-left: 2%;
  width: 50%;
  background-color: transparent;
  /* margin-right: 30px; */
  margin-left: -1px;
}

.next {
  background-color: #fee500;
  padding: 10px 10px;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 10%;
  margin-left: 2%;
  width: 50%;
}

/* .back:focus{
    border: 1px solid black;
} */

.back:hover {
  border: 1px solid black;
}

.next {
  width: 50%;
}

input {
  /* outline-color: green; */
  outline: none;
  /* outline-style: none; */
}

.divider-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.divider-line {
  flex-grow: 1;
  height: 1px;
  background-color: #d2d4c8;
  margin: 0 10px;
}

.divider-text {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* You can adjust the text color as needed */
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.heading {
  font-family: "GilroyBold";
}
