.profile__container__main {
  display: flex;
  background-color: #f2f2f2;
}

.profile__container__main .profile__main {
  width: 75%;

  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
}

.profile__main__container {
  display: flex;
}

.profile__container__main .profile__main .cover__photo {
  width: 100%;
  height: 35vh;
  background: url("/public/profile/cover__photo.png") no-repeat center
    center/cover;
}

.profile__main__left {
  flex: 0.25;
  margin-top: -33vh;
  margin-left: 2rem;
}

.profile__main__right {
  flex: 0.75;
}

.profile__container__main .something__else {
  width: 25%;
}
