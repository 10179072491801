.profile__details__container {
  background-color: #ffffff;
  z-index: 999;
  min-height: 100vh;
  padding: 30px 30px 6px;
}

.profile__foot {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "GilroyBold";
  margin-top: 20px;
  padding-bottom: 12px;
}
