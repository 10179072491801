.propicname {
  margin-bottom: 0;
  font-family: "GilroyBold";
  margin-left: 5px;
}

.cup {
  background-color: blue;
  margin-left: 5px;
}

.skillcontainer {
  margin-top: 10px;
}

.skill1 {
  background-color: #d5eb77;
  /* margin-left: 5px; */
  margin-right: 5px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  font-family: "GilroyLight";
}

.skill2 {
  background-color: #e2daff;
  /* margin-left: 5px; */
  margin-right: 5px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-family: "GilroyLight";
}

.skill3 {
  background-color: #ffdca9;
  /* margin-left: 5px; */
  margin-right: 5px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-family: "GilroyLight";
}

.skill4 {
  background-color: #ffcfff;
  /* margin-left: 5px; */
  margin-right: 5px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-family: "GilroyLight";
}

.skill5 {
  background-color: #ffe445;
  /* margin-left: 5px; */
  margin-right: 5px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-family: "GilroyLight";
}

.p-title {
  font-family: "GilroyLight";
  padding-bottom: 0%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  margin-top: 5px;
  font-size: 12px;
  color: #717171;
}

.p-option {
  font-family: "GilroyBold";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.detailsBtn {
  border: 1px solid #ccc;
  font-family: "GilroyLight";
  margin-right: 22px;
}

.collabBtn {
  border: none;
  font-family: "GilroyLight";
  background-color: #fee500;
}
