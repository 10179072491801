.sender__message__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sender__message__container .message {
  background: #efeff0;
  /* border: 2px solid #c9c9c9; */
  /* border-radius: 3px; */
  border-radius: 0px 4px 4px 4px;
  max-width: 46%;
  word-break: break-word;
  padding: 10px;
}

.sender__message__container .message p {
  font-family: "GilroyLight";
  font-size: 16px;
  text-align: left;
  color: #000;
  font-weight: 400;
  line-height: normal;
  word-spacing: 0.5px;
}

.sender__message__container .date {
  font-family: "GilroyLight";
  color: #979494;
  font-size: 12px;
  margin-bottom: 3px;
}

.sender__message__container .heart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-top: 2px;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
