.details-section {
  font-family: var(--font-gilroy-extrabold);
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 30px;
}

.gap {
  padding: 16px;
}

.activity-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.detail-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* margin: 12px 0; */
  margin-bottom: 20px;
  margin-top: 15px;
}

.details-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
  font-family: "GilroyBold";
}

.toggle-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #717171;
  font-family: "GilroyLight";
}

.detail-label {
  font-size: 14px;
  font-weight: 500;
  font-family: "GilroyBold";
}

.detail-value {
  font-size: 14px;
  color: #717171;
  font-weight: 400;
  font-family: "GilroyBold";
}

.details__percent {
  color: #fff;
  padding: 3px 6px;
  border-radius: 12px;
  background-color: blue;
  font-size: 14px;
  font-weight: 400;
  font-family: "GilroyLight";
}

.underline {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
