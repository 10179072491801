/* HorizontalNavigation.css
.horizontal-scroll-container {
    overflow: hidden;
    position: relative;
  }
  
  .horizontal-scroll {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .item {
    flex: 0 0 auto;
    width: 100%;
  }
   */

.viewall {
  margin-top: 50px;
}
