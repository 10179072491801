.type-heading {
  color: #717171;
  font-size: 14px;
  font-family: "GilroyBold";
}

.type-category {
  color: #717171;
  font-size: 14px;
  font-family: "GilroyLight";
}
