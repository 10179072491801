.supported-companies {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company {
  display: grid;
  /* margin-top: 30px; */
  /* justify-content: space-between; */
  align-items: center;
  padding-inline: 12%;
}

.company-icon {
  height: 50%;
  width: 120%;
  margin-bottom: 30px;
}

.company-box {
  height: 30px;
  width: 120%;
  background-color: #d9d9d9;
}
