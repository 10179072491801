/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.container {
  display: flex;
  height: 100%;
}

.navbar {
  height: 60px;
}

.logo {
}

.nav-elements {
  display: flex;
  justify-content: right;
  list-style-type: none;
  align-items: right;
  text-align: right;
  margin-left: 350px;
}

.nav-elements ul {
  display: flex;
  justify-content: right;
  list-style-type: none;
  align-items: right;
  text-align: right;
  padding-inline: 0;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 20px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
  background-color: yellow;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
} */

.active-nav-item {
  background-color: #ffe500; /* Set your desired background color */
}

div {
  font-family: "GilroyLight";
}
