.logo-container {
  /* display: flex;
    align-items: center; */
}

.logo-image {
  width: 130px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  /* margin-right: 5px; Adjust the spacing as needed */
  margin: 10px;
  margin-left: 50px;
}

.logo-title {
  /* font-size: 24px; Adjust the font size as needed */
  /* font-weight: bold; */
  /* color: #333; Adjust the color as needed */
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
