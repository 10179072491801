.profile__overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.profile__overview__name {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-bottom: 12px;
}

.profile__overview__name h2 {
  font-family: "GilroyBold";
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.profile__overview__name svg {
  vertical-align: middle;
}

.profile__overview__info p {
  font-family: "GilroyLight";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 6px 0;
}

.profile__overview__username {
  text-align: center;
  color: #717171;
}

.profile__overview__ratings {
  display: flex;
  justify-content: center;
  font-family: "GilroyLight";
  margin: 12px auto;
}

.profile__overview__ratings span {
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
  color: #717171;
}

.svg__item {
  display: inline-block;
}

.profile__other__info {
  margin-top: 20px;
}

.profile__info {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
  margin: 8px 0;
}

.profile__other__info > .profile__info > span {
  padding: 6px;
  font-family: "GilroyLight";
  font-size: 14px;
  font-weight: 400;
}

.profile__other__info > .profile__info > span:first-child {
  flex-grow: 1;
}

.profile__other__info > .profile__info > span:first-child {
  flex-grow: 1;
}
