.popular2 {
  margin-right: 20px;
  margin-left: 50px;
  font-family: "GilroyBold";
  margin-top: 5px;
}

.searchopt {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  background-color: #efeff0;
  height: 40px;
  justify-content: center;
  font-family: "GilroyLight";
  color: #717171;
  font-size: 13px;
}
