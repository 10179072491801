.res-pp {
  border-radius: 4px;
}

.sep-dot {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.res-title {
  font-family: "GilroyBold";
}

.res-name {
  font-family: "GilroyLight";
}

.tag-1 {
  font-family: "GilroyLight";
  font-size: 10px;
  background-color: #fff2e6;
  padding: 2px;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-bottom: -2px;
}

.tag-2 {
  font-family: "GilroyLight";
  font-size: 10px;
  background-color: #e2daff;
  padding: 2px;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-bottom: -2px;
}

.res-tz {
  font-family: "GilroyBold";
}

.res-time {
  font-family: "GilroyLight";
  font-size: 12px;
  color: #717171;
  margin-top: -10px;
  /* margin-right: -100px; */
}

.type1 {
  font-family: "GilroyLight";
  background-color: rgb(178, 126, 226);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
}

.ratio1 {
  font-family: "GilroyLight";
  background-color: rgb(188, 166, 209);
  height: 26px;
  margin-right: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 12px;
}

.type2 {
  font-family: "GilroyLight";
  background-color: rgb(204, 106, 171);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
}

.ratio2 {
  font-family: "GilroyLight";
  background-color: rgb(219, 176, 196);
  height: 26px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 12px;
}

.note {
  font-family: "GilroyLight";
  font-size: 12px;
}

.detailBtn {
  padding: 10px;
  height: 45px;
  width: 150px;
  font-family: "GilroyLight";
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.applyBtn {
  padding: 10px;
  height: 45px;
  width: 150px;
  font-family: "GilroyLight";
  border: none;
  border-radius: 4px;
  background-color: #fee500;
  margin-right: 5px;
}
