.container-fluid {
  padding-top: 7%;
}
.form-group {
  margin-top: 1rem;
  font-family: "GilroyLight";
}
.form-group label {
  font-family: "GilroyBold";
  margin-bottom: 0.5rem;
  font-size: 15px;
}

.sup {
  overflow-x: hidden;
}

/* .btn {
    height: 50px;
}
.btn-block {
    height: 50px;
    font-weight: bold;
    font-size: 16px;
} */

input {
  /* outline-color: green; */
  outline: none;
  /* outline-style: none; */
}

.divider-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.divider-line {
  flex-grow: 1;
  height: 1px;
  background-color: #d2d4c8;
  margin: 0 10px;
}

.divider-text {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* You can adjust the text color as needed */
}
