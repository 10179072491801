.receiver__message__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.receiver__message__container .message {
  background: #ffe500;
  border-radius: 4px 0px 4px 4px;
  max-width: 46%;
  word-break: break-word;
  padding: 10px;
}

.receiver__message__container .message p {
  font-family: "GilroyLight";
  font-size: 16px;
  text-align: right;
  font-weight: 400;
  color: #000;
  line-height: normal;
  word-spacing: 0.5px;
}

.receiver__message__container .date {
  font-family: "GilroyLight";
  color: #889696;
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: 400;
}

.receiver__message__container .heart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-top: 2px;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
